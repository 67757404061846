<template>
    <div class="loginBG">
      <Button type="primary" class="clearBtn" @click="clearBtn()"><Icon type="md-arrow-round-back" />退出登录</Button>
      <div class="clearIten"></div>
        <div class="title">
          <div :class="tabNum == 0?'actives':''" @click="checkTab(0)">听写与默写 <div class="dot" v-if="tabNum ==0"></div></div>
          <div :class="tabNum == 1?'actives':''" @click="checkTab(1)">课文填空 <div class="dot" v-if="tabNum ==1"></div></div>
          <div :class="tabNum == 2?'actives':''" @click="checkTab(2)">古诗文练习 <div class="dot" v-if="tabNum ==2"></div></div>
        </div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <div class="loginQr" v-if="!queryListData">
          <img  src="../assets/miniQr.jpg" alt="" srcset="">
        </div>
        
        <div class="loginInfo" style="color:red" v-if="!queryListData">您还没有制作任何练习卷</div>
        <div class="loginInfo" v-if="!queryListData">扫码进入微信小程序即可制作词语练习卷进行投屏</div>
        <div class="contentBox" v-if="queryListData && tabNum ==0">
            <div class="listItem" v-for="(item,index) in queryListData" :key="index">
              <span class="postIcon"></span>
              {{item.DictationName}}
               <span v-if="item.MultiBlock" style="font-size:14px"></span>
               <Button type="primary" class="showBtn" @click="showBtn(item)">投屏听写</Button>
               <Button type="primary" class="showBtn1" @click="showStudyBtn(item)">投屏跟读</Button>
              
            </div>
            <!-- <Page :total="total" /> -->
        </div>

        <div class="contentBox" v-if="queryListData && tabNum !=0">
            <div class="listItem" v-for="(item,index) in queryListData" :key="index">
              <span class="postIcon"></span>
              {{item.title}}
               <Button type="primary" class="showBtn1" @click="goPoemPage(item)">查看内容</Button>
              
            </div>
        </div>
       
    </div>

</template>
  
<script>
import axios from "axios";
export default {
    data() {
      return {
        queryListData:[],
        total:0,
        spinShow:true,
        from:'',
        tabNum:0
      };
    },
    created() {
      this.queryList()
      this.from = this.$route.query.from
    },
    mounted() {
     
    },
    
    methods: {
      clearBtn(){
        window.localStorage.removeItem('sessionId')
        this.$router.push('login?from='+this.from)
      },
      goPoemPage(data){
        this.$router.push({name:'PoemPage',query:{id:data.fid,tab:this.tabNum}});
      },
      showBtn(data){
        // if(data.MultiBlock){
        //     this.$Message.warning('分课显示不支持投屏听写');
        //   return false
        // }
         this.$router.push({name:'wordPage',query:{id:data.DictationID}});
      },
      showStudyBtn(data){
        // if(data.MultiBlock){
        //     this.$Message.warning('分课显示不支持投屏跟读');
        //   return false
        // }
        this.$router.push({name:'studyPage',query:{id:data.DictationID}});
      },
      checkTab(type){
          this.tabNum = type
          if(type== 0){
            this.queryList()
          }else if(type == 1){
            this.queryListFill()
          }else{
            this.queryPoemList()
          }
      },
      queryList(){
      const that = this
        axios({
          headers: {
            'Content-Type': 'application/json',
            'Authorization': window.localStorage.getItem('sessionId'),
          },
          method: 'POST',
          url: '/Home/GetDictations',
          data: {
            id: window.localStorage.getItem('sessionId'),
            page: 1,
            num: 1000
            }
          })
        .then((res) => {
          if(res.data.success){
            that.spinShow = false
            console.log(res.data.data)
           that.queryListData = res.data.data
          }else{
            if(res.data.msg == '无效的sessionid'){
                 this.$router.push('login?from='+this.from)
            }
            // that.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
           that.$Message.error(err);
        });
       },
       queryListFill(){
      const that = this
        axios({
          headers: {
            'Content-Type': 'application/json',
            'Authorization': window.localStorage.getItem('sessionId'),
          },
          method: 'GET',
          url: '/v2/course/fill/list',
          data: {
            id: window.localStorage.getItem('sessionId'),
            page: 1,
            num: 1000
            }
          })
        .then((res) => {
          if(res.data.success){
            that.spinShow = false
           that.queryListData = res.data.data.fills
          }else{
            if(res.data.msg == '无效的sessionid'){
                 this.$router.push('login?from='+this.from)
            }
            // that.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
           that.$Message.error(err);
        });
       },
       queryPoemList(){
      const that = this
        axios({
          headers: {
            'Content-Type': 'application/json',
            'Authorization': window.localStorage.getItem('sessionId'),
          },
          method: 'GET',
          url: '/v1/poem/fill/list',
          data: {
            id: window.localStorage.getItem('sessionId'),
            page: 1,
            num: 1000
            }
          })
        .then((res) => {
          if(res.data.success){
            that.spinShow = false
            console.log(res.data.data)
           that.queryListData = res.data.data.fills
          }else{
            if(res.data.msg == '无效的sessionid'){
                 this.$router.push('login?from='+this.from)
            }
            // that.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
           that.$Message.error(err);
        });
       },
    },
  };
  </script>
  
  <style scoped>
    .loginBG{
        width: 100%;
        height: 100%;
        background: url('https://r.bjwxjs.com/PC_kpy/pc_topic_bg.png') 0 0 / 100% 100% no-repeat;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    .contentBox{
      overflow: auto;
      width: 900px;
      height: 80%;
      margin: 0 auto;
      padding: 0 10px 200px 10px;
    }
    .title{
      font-size: 30px;
      font-weight: 500;
      color: #666;
      margin-top: 50px;
      width: 900px;
      margin: 50px auto 0;
      display: flex;
      padding-bottom: 20px;
    }
    .title > div{
      margin-right: 20px;
      position: relative;
      cursor: pointer;
    }
    .actives{
      color: #303133;
      font-weight: 600;
    }
    .dot{
      position: absolute;
      top: 50px;
      height: 6px;
      width: 50px;
      border-radius: 3px;
      left: 50%;
      transform: translate(-50%,0);
      background: rgb(0, 87, 255);
    }
    .listItem{
      background: #FFFFFF;
      box-shadow: 0px 2px 10px 0px #95C9FF, 0px 2px 10px 0px rgba(0,0,0,0.12);
      border-radius: 12px;
      height: 80px;
      margin-top: 20px;
      position: relative;
      line-height: 80px;
      padding: 0 20px 0 40px;
      font-size: 28px;
      font-weight: 400;
      color: #303133;
    }
    .postIcon{
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0,-50%);
      width: 10px;
      height: 40px;
      background: #FFB247;
      box-shadow: 0px 2px 10px 0px #95C9FF;
      border-radius: 0px 4px 4px 0px;
      box-sizing: border-box;
    }
    .showBtn{
      position: absolute;
      right: 160px;
      top:50%;
      transform: translate(0,-50%);
      width: 130px;
      height: 48px;
      font-size: 26px;
      line-height: 32px;
    }
    .showBtn1{
      position: absolute;
      right: 20px;
      top:50%;
      transform: translate(0,-50%);
      width: 130px;
      height: 48px;
      font-size: 26px;
      line-height: 32px;
    }
    .showBtn1 span{
      margin-bottom: 3px !important;
    }
    .showBtn span{
      margin-bottom: 3px !important;
    }
    .ivu-btn>span{
      vertical-align: center;
    }
    ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .ivu-spin-large /deep/.ivu-spin-dot{
      width: 100px;
      height: 100px;
    }
    .loginQr{
      width: 300px;
      height: 300px;
      background: #FFFFFF;
      border-radius: 10px;
      backdrop-filter: blur(10px);
      margin:200px auto 0;
      padding: 20px;
    }
    .loginInfo{
      font-size: 26px;
      font-weight: normal;
      color: #303133;
      letter-spacing: 4px;
      text-align: center;
      margin-top: 30px;
    }
    .loginQr img{
        width: 100%;
        height: 100%;
        margin-bottom: 10px;

    }
    .clearBtn{
      position:fixed;
      top: 40px;
      right: 50px;
    }
    scrollbar-width:none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    
   
  </style>
  